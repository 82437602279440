// Pseudo element
body {
  overflow-x: hidden !important;
}

// Header
.headerContent .mainCallToAction {
  margin: 0 0 0 auto !important;

  a {
    height: 100px;
    margin: 0;
    text-decoration: none;

    @media (max-width: 900px) {
      height: 80px;
    }

    @media (max-width: 576px) {
      height: 70px;
    }
  }
}

a.menuMainAlt {
  display: inline-flex;
}

@media (max-width: 900px) {
  a.mainLogo {
    width: 245px !important;
    height: 70px !important;
  }
}

.menuMain.active:before {
  content: "";
  display: block;
  position: absolute;
  background-image: url($assets-path + "icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 600px;
  height: 600px;
  right: 0;
  bottom: 22.5%;
  transform: translate(40%, 40%);
  z-index: -1;
  opacity: 0.085;

  @media (max-width: 768px) {
    width: 400px;
    height: 400px;
    transform: translate(40%, 70%);
    opacity: 0.075;
  }
}

// Nav sub items
.menuMain ul.subMenu a {
  padding: 10px 30px; // JK - This should be possible within the framework
  font-size: 1.25em; // JK - This should be possible within the framework
}

// Nav - pseudo element icon - prevent from scrolling
nav.menuMain {
  overflow-x: hidden;
}

// Tagline
.headerContent span.tagline {
  // JK - Lots of this should be possible within the framework variables
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
  max-width: 120px;
  letter-spacing: 0.18em;
  line-height: 1.25;
  margin-left: 1rem;
  margin-right: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  @media (max-width: 975px) {
    // Use variables
    display: none;
  }
}

// Search bar
.menuMain .header-search {
  min-height: 65px;
}

.menuMain .header-search #siteSearch {
  max-width: calc(100% - 48px);
  margin: 0;
  border: 2px solid black;
}

// Logo
a.mainLogo {
  margin: 0 1rem 0 20px;

  &:hover {
    background-image: url($assets-path + "logo_reverse.svg");
  }
}

// Images filter
// .homepage {

//   .banner,
//   img[class*="image"],
//   .carouselSlide img {
//     filter: grayscale(100%) contrast(1);
//   }
// }

// carousel + carouselSlideDetails
.carousel {
  z-index: 1;
}

a.carouselControlPrev:before {
  content: "\f0A8";
}

a.carouselControlNext:before {
  content: "\f0A9";
}

@media (min-width: 1025px) {
  li.carouselSlide {
    overflow: visible;
  }

  .carouselSlideDetail {
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      background-color: $carousel-details-background-colour;
      width: 400px;
      height: 100%;
      top: 0;
      right: 100%;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      background-color: $carousel-details-background-colour;
      width: 20px;
      height: 100%;
      bottom: 0;
      left: 100%;
      transform-origin: bottom left;
      transform: rotate(-3deg);
    }
  }
}

@media (min-width: $carousel-breakpoint + 1px) {
  .carouselDetailWrapper {
    padding-left: 100px;
    padding-right: 100px;
  }

  .carouselSlideDetail {
    transform: translateY(6rem);
  }
}

.footerBox[class*="ewsletter"] {
  .formQuestion {
    border-bottom: 3px solid $black;
  }

  input[type="text"] {
    color: #fff;
    border-bottom: 1px solid white;
  }
}

// homeIntro
.homeIntroWrapper {
  z-index: -1;
}

.homeIntro * span {
  color: $brand-primary;
  position: relative;
  display: inline-flex;
  margin: 0 5px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -1.25%;
    left: -1.25%;
    width: 102.5%;
    height: 102.5%;
    background-color: $black;
    transform: rotate(-0.5deg);
    z-index: -1;
  }
}

@media (max-width: 768px) {
  .homeIntro * {
    font-size: 1.4rem;
  }

  .homeIntro * span {
    color: $black;

    &:before {
      top: 95%;
      background-color: $grey;
      height: 4px;
      transform: rotate(-0.45deg);
    }
  }
}

@media (min-width: 1024px + 1px) {
  .homeIntroWrapper {
    padding: 9rem 0 6rem 0;
  }
}

// span styling
span.text-box {
  color: $brand-primary;
  position: relative;
  display: inline-flex;
  margin: 0 5px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -1.25%;
    left: -1.25%;
    width: 102.5%;
    height: 102.5%;
    background-color: $black;
    transform: rotate(-0.5deg);
    z-index: -1;
  }
}

// HomeFeatures
@media (max-width: 768px) {
  .homeFeatures {
    padding: 2.5rem 0;
  }

  .homefeaturecategory-homeboximpactstats h2 {
    text-align: center !important;
  }
}

// homeStats
.homefeaturecategory-homeboximpactstats {
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    position: absolute;
    background-image: url($assets-path + "icon.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.075;
    width: 2800px;
    height: 2800px;
    top: 50%;
    left: 62%;
    transform: translate(-50%, -50%);
    filter: invert(1);
  }

  @media (max-width: 768px) {
    &:after {
      left: 50%;
      width: 4100px;
      height: 4100px;
    }
  }
}

.homefeaturecategory-homeboximpactstats tr {
  min-width: unset;

  @media (min-width: 1101px) and (max-width: 1170px) {
    td:first-child h2 {
      font-size: 5rem;
    }
  }
}

// homeFeedBox2
.homeFeedBox2 {
  background-color: $grey-lightest;
}

// homequick giving mobile
@media (max-width: 1024px) {
  .homefeaturecategory-homeboxquickgiving {
    padding: 5rem 0 !important;
  }
}

// blockquote
blockquote {
  position: relative;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    background-color: $blockquote-background-colour;
    height: 100%;
    width: 500px;
    top: 0;
  }

  &:before {
    right: 100%;
  }

  &:after {
    left: 100%;
  }
}

// Stop blockquote before & after breaking viewport
.contentBlockWrapper {
  overflow: hidden;
}

// FAQs
.listedFaq h2 a {
  color: $brand-primary;
}

.listedFaq .buttonIcon {
  border-radius: 50%;
}

.listedFaq .accordianHeader:hover {
  background-color: $black;
}

// Footer Logos
.footerBox5 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  img {
    max-width: 80px;
    max-height: 80px;
    margin: 0rem 2rem;
    filter: grayscale(100);
    transition: 300ms all ease;

    &:hover {
      filter: none;
      transition: 300ms all ease;
    }

    @media (max-width: 768px) {
      margin: 1rem;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

// Carousel slide details fix
.carouselSlideDetail {
  padding-right: 30px;
}

// DES-4656
.mainCallToAction .cta-button:not([class*="donate"]),
.mainCallToAction .cta-button.donate,
.menuMainAlt {
  background-color: transparent;
  color: $grey-dark;
}

.footerBox[class*=ewsletter] button {
  color: white;
}

.raisedWrapper .totaliserFigure,
.formQuestion.donationAmount:not(.ccAmount) .donationAmountFigure,
.coverCostLabel {
  color: $grey-dark;
}

@media (min-width: 1024px) {
  .footerBox2 {
    grid-column: 6/span 6;
    margin-left: 50%;
  }

  .footerBox3 {
    grid-column: 11/span 2;
  }

  .footerBox4 {
    grid-column: 13/span 3;
  }
}

.footerBox5 {
  grid-column: 1 / span 5;
}

.footerBox6 {
  grid-column: 7 / span 5;
}

.footerBox7 {
  grid-column: 13 / span 2;
  .socialIcons a:before {
      color: #fff;
  }
}

.FooterLogos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  img {
    max-height: 70px;
    filter: grayscale(1.0);
    margin: 10px;
  }
}

@media (max-width: 1260px) {

  .footerBox5,
  .footerBox6,
  .footerBox7 {
    grid-column: 3 / span 12;
  }
}

.commentDonationAmount {
  color: #231f20;
}


// Shop product - Layout
.continueShoppingWrapper { padding-top: $spacer; }
.productPostPage .post .contentBlockWrapper {
  margin-top: 0;
}
.productPurchase { background-color: #F0F0F0; }
.productContentWrapper {
  margin-top: 0;
  flex-direction: column;
  .productBannerWrapper {
    width: 100%;
    margin-right: 0;
    margin-bottom: $spacer;
  }
}
