//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    // $prefab: 4
    $prefab: 1
  )
}

// Choose your card style for home feature cards
// .homeFeatures .homeBox1 {
//   @include card (
//     $card-image-background-colour: rgba($white, 0.001),
//     $card-hover-image-opacity: 1,
//     $card-border: none
//   );
//
//   @media (min-width: 769px) {
//     @include card-side-by-side(
//       $prefab: 2,
//       $card-side-by-side-image-position: left,
//       $card-side-by-side-image-width: 60%,
//       $card-side-by-side-vertical-text-position: center,
//     );
//   }
//
//   @media (max-width: 768px) {
//     @include card-basic (
//       $prefab: 6
//     );
//   }
//
// }

// .homeFeatures .homeBox2,
// .homeFeatures .homeBox3 {
.homeFeatures .homeFeature {
  @include card (
    $card-image-background-colour: rgba($white, 0.001),
    $card-hover-image-opacity: 1
  );
  @include card-basic (
    $prefab: 1
  );
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 1
);

// Specify a card layout for each individual feed
.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 2
  );
  .feedItem {
    @include card (
      $card-hover-image-opacity: 1,
      $card-details-background-colour: $brand-primary,
      $card-heading-colour: $black,
      $card-summary-colour: $black,
      $card-hover-details-background-colour: $black,
      $card-hover-heading-colour: $brand-primary,
      $card-hover-summary-colour: $white
    );
    @include card-basic(
      $prefab: 1
    );
  }
}

.homeFeed:not(.homeFeedBox1) .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 3
  );
}

// Choose your card style for home feed cards
.feedItem {
  @include card (
    $card-hover-image-opacity: 1,
  );
  @include card-basic(
    $prefab: 1
  );
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card (
    $card-image-background-colour: transparent,
    $card-hover-image-opacity: 1,
    $card-details-background-colour: $grey
  );
  @include card-basic(
    $prefab: 1
  );
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above, you can remove the ':not(.listedProduct)' selector above and squash this together into more efficient CSS output
.listedProduct {
  @include card (
    $card-image-background-colour: rgba($white, 0.001),
    $card-hover-details-background-colour: lighten($black, 5%),
    $card-hover-heading-colour: $brand-primary,
    $card-hover-summary-colour: $white
  );
  @include card-basic(
    $prefab: 1
  );
}
