// Config values
$platform-name: "brandxproductionsinc";
$assets-path: "../assets/";

// Colours
$brand-primary: rgb(252, 227, 0);
$brand-secondary: rgb(252, 227, 0);

$donate-colour: $brand-primary;

// Create greyscale
$black: #231f20;
$grey-dark: #231f20;
$grey: #505050;
$grey-light: #6d6e6f;
$grey-lighter: #f0f0f0;
$grey-lightest: #f0f0f0;
$white: #fff;

// Layout
// $container-max-width: 1320px;
$container-max-width: 100%;
$gap-width: 30px;
$site-bleed: 20px;

$border-radius: 0;
$border-colour: $grey-lighter;
$box-shadow: none;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
);

// Spacers
$spacer: 1.25rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Misc
$body-bg: $white;
$body-colour: $black;
$profile-pics-enabled: true;
$border-width: 5px;
$date-ordinals-enabled: true;

// Transitions
$transition-duration: 0.1s;
$transition-duration-slow: 0.5s;
$transition-curve: ease;

// Logo
$logo: "logo.svg";
$logo-width: 250px;
$logo-height: 60px;
$logo-absolute: false;
$logo-breakpoint: 900px;
$logo-mobile: $logo;
$logo-mobile-width: $logo-width * 0.7;
$logo-mobile-height: $logo-height * 0.7;

// Typograph
$font-family-base: "AvenirNextLTW01-Regular", sans-serif;
$font-size-base: 1rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;

$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.45;

$font-size-h1: 2.5em;
$font-size-h2: 2.5em;
$font-size-h3: 2em;
$font-size-h4: 1.75em;
$font-size-h5: 1.5em;
$font-size-h6: 1.25em;

$headings-margin-top: 0.5em;
$headings-margin-bottom: 0.5em;
$headings-font-family: "Novecento Sans W03 Bd", sans-serif;
$headings-font-weight: bold;
$headings-line-height: 1.2;
$headings-colour: inherit;
$headings-text-transform: uppercase;
$headings-letter-spacing: 0.075em;

$hr-border-colour: $border-colour;
$hr-border-width: $border-width;

// Links
$link-colour: $body-colour;
$link-decoration: none;
$link-hover-decoration: underline;
$link-hover-style: darken;

// Icon font
$icon-font-family: "FontAwesome";

// Buttons
$btn-background-colour: $black;
// $btn-text-colour: text-contrast($btn-background-colour);
$btn-text-colour: $brand-primary;
$btn-padding-x: 1.6rem;
$btn-padding-y: 0.8rem;
$btn-font-family: $headings-font-family;
$btn-font-weight: $headings-font-weight;
$btn-text-transform: uppercase;
$btn-line-height: 1.25;
$btn-hover-style: darken;
$btn-transition-duration: $transition-duration;

// Donate button
$donate-btn-background-colour: $brand-primary;
$donate-btn-colour: $body-colour;

// Social icons
$social-icons-background-colour: transparent;
// $social-icons-colour: text-contrast($social-icons-background-colour);
$social-icons-colour: $black;
$social-icons-hover-colour: lighten($social-icons-colour, 10%);
$social-icons-width: 52px;
$social-icons-height: 52px;
$social-icons-font-size: 1.45rem;
$social-icons-gap: 0;
$social-icons-border-radius: 0;

// Header specific
$social-icons-header-background-colour: transparent;
$social-icons-header-colour: $black;
$social-icons-header-hover-colour: $grey;

// Footer specific social icons
$social-icons-footer-background-colour: $social-icons-background-colour;
$social-icons-footer-colour: $brand-primary;
$social-icons-footer-hover-colour: darken($brand-primary, 10%);

// Share this page
$share-enabled: true;
$share-text-align: left;
$share-label-font-family: $headings-font-family;
$share-label-font-size: $font-size-h3;
$share-label-font-colour: $body-colour;

// Form elements
$input-padding-y: 0.75rem;
$input-padding-x: 0.75rem;
$input-line-height: 1.3;
$input-colour: $body-colour;
$input-border-colour: #ced4da;
$input-border: 1px solid $input-border-colour;
$input-placeholder-colour: $grey-light;

// Field groups
$field-group-label-width: 230px;

// Tables
$table-cell-padding: 0.75rem;

// Card
$card-gap-width: $gap-width;
$card-border-radius: $border-radius;
$card-text-align: left;
$card-image-on: true;
$card-image-opacity: 1;
$card-image-background-colour: transparent;
$card-image-padding: 0;
$card-image-border-radius: 0;
$card-details-padding: $spacer;
$card-details-background-colour: $black;
$card-heading-font-family: $headings-font-family;
$card-heading-text-transform: $headings-text-transform;
$card-heading-colour: $brand-primary;
$card-heading-font-size: $font-size-h3;
$card-summary-enabled: true;
$card-summary-colour: text-contrast($card-details-background-colour);
$card-summary-font-size: $font-size-base;
$card-footer-on: false;
$card-footer-background-colour: rgba($black, 0.01);
$card-footer-colour: $white;
$card-min-width: 280px;
$card-hover-image-scale: 1.015;

// Card text overlay
$card-text-overlay-show-summary-on-hover: false;
$card-text-overlay-summary-always-on: true;
$card-text-overlay-details-max-width: 450px;
$card-text-overlay-details-position-y: bottom;
$card-text-overlay-details-position-x: left;

// Card side by side
$card-side-by-side-image-position: left;
$card-side-by-side-image-width: 50%;
$card-side-by-side-vertical-text-position: top;
$card-side-by-side-breakpoint: map-get($breakpoints, md);

// Card hover state
$card-hover-image-opacity: 0.25;
$card-hover-image-scale: 1.025;
$card-hover-details-background-colour: $brand-primary;
$card-hover-heading-colour: $body-colour;
$card-hover-summary-colour: $body-colour;

// Event cards
$event-card-floating-date: true;
$event-card-floating-date-background-colour: $brand-primary;
$event-card-floating-date-colour: $body-colour;
$event-card-floating-date-margin-y: 0.25rem;
$event-card-floating-date-margin-x: 0.25rem;

// Menu admin
$menu-admin-enabled: false;
// $menu-admin-max-width: 100%;
// $menu-admin-background-colour: $black;
// $menu-admin-contents-max-width: $container-max-width;
// $menu-admin-font-size: $font-size-small;
// $menu-admin-align-items: left;
// $menu-admin-link-colour: $white;
// $menu-admin-absolute: false;
// $menu-admin-login-link-enabled: true;
// $menu-admin-manager-link-enabled: true;
// $menu-admin-my-details-link-enabled: true;
// $menu-admin-logout-link-enabled: true;

// Header
$header-background-colour: $white;

// Header content
$header-content-max-width: $container-max-width;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: 0;
$header-content-padding-bottom: 0;
$header-content-padding-left: 0;
$header-content-padding-right: 0;

// Tagline
$tagline-enabled: true;
$tagline-font-family: "Mongolian Baiti";
$tagline-font-weight: $font-weight-normal;
$tagline-font-size: $font-size-h5;
$tagline-colour: $headings-colour;
$tagline-above-cta-breakpoint: 0;
$tagline-breakpoint: map-get($breakpoints, md);

// Main call to action
$cta-btn-background-colour: $grey;
$cta-btn-colour: text-contrast($cta-btn-background-colour);
$cta-btn-gap: 0;
$cta-padding-right-at-nav-breakpoint: 6em;
$cta-btn-breakpoint: map-get($breakpoints, md);
$cta-donate-on-appeal: false;

// Search
$header-search-enabled: false;
$header-search-margin-right: 0;
$header-search-breakpoint: 4000px;

// Search - input
$header-search-input-max-width: 240px;
$header-search-input-margin-right: 0;
$header-search-input-padding: $input-padding-y $input-padding-x;
$header-search-input-placeholder-colour: $input-placeholder-colour;
$header-search-input-border-width: 1px;
$header-search-input-background-colour: rgba($white, 0.35);
$header-search-input-focus-background-colour: rgba($white, 0.35);
$header-search-input-focus-colour: text-contrast(
  $header-search-input-focus-background-colour
);

// Search - button
$header-search-button-width: 48px;
$header-search-button-height: 48px;
$header-search-button-background-colour: $btn-background-colour;
$header-search-button-icon-font-size: $social-icons-font-size;
$header-search-button-icon-colour: text-contrast(
  $header-search-button-background-colour
);

// Search when in nav
$nav-search-enabled: true;
$nav-search-input-padding: $header-search-input-padding;
$nav-search-input-placeholder-colour: $header-search-input-placeholder-colour;
$nav-search-input-border-width: 0;
$nav-search-input-background-colour: $header-search-input-background-colour;
$nav-search-button-background-colour: $header-search-button-background-colour;
$nav-search-button-icon-colour: text-contrast(
  $nav-search-button-background-colour
);

// Social icons
$header-social-icons-margin-right: 0;
$header-social-icons-margin-bottom: 0;
$header-social-icons-above-cta-buttons: false;
$header-social-icons-breakpoint: map-get($breakpoints, sm);

// Sticky header
$header-sticky-enabled: true;
$header-sticky-background-colour: $header-background-colour;
$header-sticky-logo-width: $logo-width;
$header-sticky-logo-height: $logo-height;
$header-sticky-social-icons-enabled: true;
$header-sticky-search-enabled: true;
$header-sticky-cta-btns-enabled: true;
$header-sticky-header-content-margin-top: 0;
$header-sticky-header-content-margin-bottom: 0;
$header-sticky-nav-margin-bottom: 0;

// Navigation
$nav-type: flyout;
$nav-font-family: $font-family-base;
$nav-breakpoint: unset;
$nav-background-colour: $brand-primary;
$nav-text-transform: uppercase;

// Top level items
$nav-top-level-item-padding: 10px 20px;
$nav-top-level-item-colour: $body-colour;
$nav-top-level-item-font-family: $headings-font-family;
$nav-top-level-item-font-size: $font-size-h4;
$nav-top-level-item-font-weight: $headings-font-weight;
$nav-top-level-item-background-colour: transparent;
$nav-top-level-item-hover-colour: $nav-top-level-item-colour;
$nav-top-level-item-hover-background-colour: rgba($black, 0.2);
$nav-top-level-chevrons-enabled: true;
$nav-top-level-chevrons-colour: $nav-top-level-item-colour;

// Submenus
$nav-submenu-width: 220px !default;
$nav-submenu-background-colour: rgba($black, 0.1);
$nav-submenu-item-padding: 10px 20px;
$nav-submenu-item-colour: $body-colour;
$nav-submenu-item-font-family: $headings-font-family;
$nav-submenu-item-font-size: $font-size-h5;
$nav-submenu-item-font-weight: $headings-font-weight;
$nav-submenu-item-hover-colour: $nav-submenu-item-colour;
$nav-submenu-item-hover-background-colour: rgba($black, 0.2);
$nav-submenu-chevrons-enabled: true;
$nav-submenu-chevrons-colour: $nav-submenu-item-colour;

// Burger button
$burger-btn-background-colour: $btn-background-colour;
$burger-btn-text-colour: $btn-text-colour;
$burger-btn-icon-breakpoint: null; // What viewport width do you want to remove the 'menu' text from the burger button?
$burger-btn-bar-breakpoint: null; // What viewport width do you want the burger button to pop under the header full width?

// Nav normal
$nav-normal-max-width: $container-max-width;
$nav-normal-margin-top: 0;
$nav-normal-margin-bottom: $spacer;
$nav-normal-contents-max-width: $container-max-width;
$nav-normal-align-items: left;
$nav-normal-mobile-background-colour: $brand-primary;
$nav-normal-mobile-top-level-item-colour: $body-colour;
$nav-normal-mobile-submenu-background-colour: rgba($black, 0.05);
$nav-normal-mobile-submenu-item-colour: $body-colour;
$nav-normal-mobile-chevrons-enabled: true;
$nav-normal-mobile-chevrons-colour: $body-colour;

// Carousel
$carousel-max-width: 100%;
$carousel-margin-top: 0;
$carousel-margin-bottom: 0;
$carousel-contents-max-width: 100%;
$carousel-breakpoint: map-get($breakpoints, lg);
$carousel-details-style: overlay;
$carousel-details-position-y: bottom;
$carousel-details-position-x: left;
// $carousel-details-background-colour: $brand-primary;
$carousel-details-background-colour: $black;
$carousel-details-max-width: 990px;
$carousel-details-min-height: auto;
$carousel-details-padding: $spacer * 2 0;
// $carousel-details-margin-y: -6rem; // Refactored to bespoke - this is kind of improper use, see DES-3490
// $carousel-details-margin-x: -2rem; // Refactored to bespoke - this is kind of improper use, see DES-3490
$carousel-details-text-align: left;
$carousel-heading-text-transform: $headings-text-transform;
// $carousel-heading-colour: $black;
$carousel-heading-colour: $brand-primary;
$carousel-heading-font-size: $font-size-h1;
$carousel-summary-enabled: true;
$carousel-summary-colour: text-contrast($carousel-details-background-colour);
$carousel-summary-font-size: $font-size-h6;
$carousel-read-more-enabled: true;
// $carousel-read-more-background-colour: $btn-background-colour;
$carousel-read-more-background-colour: $brand-primary;
$carousel-read-more-colour: $black;
$carousel-read-more-font-size: $font-size-small;

// Carousel controls
$carousel-controls-enabled: true;
$carousel-controls-button-size: 50px;
$carousel-controls-button-gap: 0px;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-y: 0px;
$carousel-controls-margin-x: 20px;
$carousel-controls-icon-colour: $btn-text-colour;
$carousel-controls-icon-font-size: 2.4rem;

// Carousel buttons
$carousel-buttons-enabled: false;

// Carousel video
$carousel-video-btn-position-y: 50%;
$carousel-video-btn-position-x: 50%;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: $carousel-details-background-colour;
$carousel-mobile-heading-colour: $brand-primary;
$carousel-mobile-summary-colour: text-contrast(
  $carousel-mobile-details-background-colour
);

// Banner min-height under sm breakpoint
$banner-min-height: 0px;

// Home intro
$home-intro-max-width: 100%;
$home-intro-background-colour: transparent;
$home-intro-background-colour: $grey-lightest;
$home-intro-content-max-width: $container-max-width;
$home-intro-content-align: left;
$home-intro-padding-y: $spacer * 3;
// $home-intro-padding-y: $spacer * 7;
// $home-intro-margin-top: 5rem;
$home-intro-margin-top: 0;
$home-intro-margin-bottom: 0;
$home-intro-text-align: left;
$home-intro-text-transform: $headings-text-transform;
// $home-intro-font-family: 'Novecento Sans W03 Bd', sans-serif;
$home-intro-font-family: "Novecento Sans W03 UltBd", sans-serif;
// $home-intro-font-size: $font-size-h1;
$home-intro-font-size: 2.5vmax;
$home-intro-font-weight: $headings-font-weight;
$home-intro-line-height: 1.55;
$home-intro-colour: text-contrast($home-intro-background-colour);

// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: transparent;
$home-features-padding-top: $spacer * 3;
// $home-features-padding-top: 0;
$home-features-padding-bottom: $spacer * 5;
$home-features-cards-entry-animation-prefab: 1;
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 3;

// Impact stats
$impact-stats-enabled: true;
// $impact-stats-max-width: calc(100% - 50px);
$impact-stats-max-width: 100%;
$impact-stats-margin-top: 0;
$impact-stats-margin-bottom: 0;
$impact-stats-padding-y: $spacer * 1.5;
$impact-stats-background-colour: $brand-primary;
$impact-stats-colour: $body-colour;
$impact-stats-text-align: left;
$impact-stats-content-max-width: $container-max-width;
$impact-stats-breakpoint: 1100px;
$impact-stats-overall-container-direction: column;
$impact-stats-direction: row;
$impact-stats-mobile-text-align: center;

// Impact heading
$impact-stats-heading-enabled: true;
$impact-stats-heading-font-size: $font-size-h1;
$impact-stats-heading-text-align: $impact-stats-text-align;
$impact-stats-heading-colour: $impact-stats-colour;
$impact-stats-heading-margin-bottom: $spacer;

// Impact individual stat
$impact-stat-min-width: 180px;
$impact-stat-direction: column;
$impact-stat-padding: 0px 0px;
$impact-stat-margin: $gap-width;

// Impact figure
$impact-stats-figure-colour: $impact-stats-colour;
// $impact-stats-figure-font-family: $headings-font-family;
$impact-stats-figure-font-family: "Novecento Sans W03 UltBd", sans-serif;
$impact-stats-figure-font-size: 6rem;

// Impact summary
$impact-stats-summary-colour: $impact-stats-colour;
$impact-stats-summary-font-size: $font-size-h4;
$impact-stats-summary-margin-top: 0;

// Home feeds
$home-feeds-max-width: $container-max-width;
$home-feeds-background-colour: transparent;
$home-feeds-padding-top: $spacer * 2;
$home-feeds-padding-bottom: $spacer * 2;
$home-feeds-cards-entry-animation-prefab: 1;
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;

// Feeds title
$feeds-title-font-family: $headings-font-family;
$feeds-title-font-size: $font-size-h1;
$feeds-title-colour: text-contrast($home-feeds-background-colour);
$feeds-title-text-align: left;

// Feed item
$feed-item-read-more-enabled: false;

// Event feed items
$feed-item-event-start-date-enabled: true;
$feed-item-event-location-enabled: false;

// Footer
$footer-boxes-count: 5;
$footer-prefab: 4;
$footer-max-width: 100%;
$footer-contents-max-width: $container-max-width;
$footer-margin-y: 0;
$footer-padding-y: $spacer * 3;
$footer-background-colour: $black;
$footer-colour: text-contrast($footer-background-colour);
$footer-link-colour: text-contrast($footer-background-colour);
$footer-text-align: left;

// Footer admin links
$footer-admin-basket-link-enabled: true;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: true;
$footer-admin-my-details-link-enabled: true;
$footer-admin-logout-link-enabled: true;

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-colour: #fff;
$newsletter-heading-font-family: $headings-font-family;
$newsletter-heading-font-size: $font-size-h1;
$newsletter-heading-text-align: left;
$newsletter-heading-text-transform: $headings-text-transform;
$newsletter-heading-margin-bottom: $spacer * 2;
$newsletter-heading-colour: $newsletter-colour;
$newsletter-input-background-colour: transparent;
$newsletter-button-height: 47px;
$newsletter-button-background-colour: $black;
$newsletter-button-colour: $brand-primary;
$newsletter-use-placeholders: true;

// Newsletter hero
$newsletter-hero-padding-y: $spacer * 3;
$newsletter-hero-contents-max-width: $footer-contents-max-width;

// Context container
$context-container-background-colour: $white;
$context-container-padding: $spacer * 2;

// Donors list
$donors-list-enabled: true;
$donors-list-profile-pics-enabled: $profile-pics-enabled;

// Posts
$post-content-max-width: 1000px;

// Header text (container for breadcrumb and page title)
$header-text-margin-top: $spacer * 1.5;
$header-text-margin-bottom: $spacer * 1.5;

// Page title
$page-title-font-size: $font-size-h1;
$page-title-font-family: $headings-font-family;
$page-title-font-weight: $headings-font-weight;
$page-title-colour: $black;
$page-title-text-transform: $headings-text-transform;
$page-title-text-align: left;
$page-title-margin-top: 0;
$page-title-margin-bottom: $headings-margin-bottom;

// Sidebar
$sidebar-enabled: false;
$sidebar-layout: adjacent;
$sidebar-adjacent-width: 300px;
$sidebar-adjacent-gap: $spacer * 5;
$sidebar-background-colour: transparent;
$sidebar-padding: 0;

// Associated lists
$associated-latest-enabled: true;
$associated-related-enabled: true;
$associated-most-read-enabled: true;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 3;
$associated-list-heading-font-size: $font-size-h1;
$associated-item-image-enabled: false;
$associated-item-heading-font-size: $font-size-h4;
$associated-item-summary-enabled: false;
$associated-item-border: false;

// Listing
$listing-content-max-width: $container-max-width;
$listing-intro-max-width: $listing-content-max-width;
$listing-cards-per-row-desktop: 3;
$listing-cards-per-row-tablet: 2;
$listing-card-min-width: $card-min-width;

// Listed post settings
$listed-post-read-more-enabled: false;
$listed-news-post-publish-date-enabled: true;
$listed-blog-post-publish-date-enabled: true;
$listed-blog-post-author-enabled: true;
$listed-event-post-start-date-enabled: true;
$listed-event-post-location-enabled: true;
$listed-appeal-totaliser-enabled: true;

// Breadcrumb
$breadcrumb-enabled: true;
$breadcrumb-margin-top: $spacer;
$breadcrumb-margin-bottom: $spacer;
$breadcrumb-max-width: $post-content-max-width;
$breadcrumb-text-align: left;

// Blockquote
$blockquote-background-colour: $brand-primary;
$blockquote-margin-y: $spacer * 3;
$blockquote-padding: $spacer * 3 0;
$blockquote-text-align: left;
$blockquote-font-size: $font-size-h1;
$blockquote-font-family: $headings-font-family;
$blockquote-font-weight: $headings-font-weight;
$blockquote-line-height: $line-height-base;
$blockquote-colour: $body-colour;

// Fundraising
$fundraising-enabled: true;

// Find a fundraiser widget
$find-a-fundraiser-widget-enabled: true;
$find-a-fundraiser-profile-pics-enabled: false;

// Top fundraisers
$top-fundraisers-enabled: true;
$top-fundraisers-profile-pics-enabled: false;

// Donation form
$donation-form-layout: columns;
$donation-form-banner-enabled: true;
$donation-form-frequency-tabs: false;

// Donation amounts
$donation-amount-min-width: $card-min-width;
$donation-amount-figure-font-size: $font-size-h2;
$donation-amount-figure-colour: $donate-colour;
$donation-amount-thumbnail-enabled: true;

// Quick giving panel
$quick-giving-type: simple;
$quick-giving-background-colour: $grey-lightest;
$quick-giving-padding: $context-container-padding;
$quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size *
  0.75;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: $donate-colour;
$quick-giving-donation-amount-figure-colour: $body-colour;
$quick-giving-donation-amount-border-width: 2px;
$quick-giving-donation-amount-border-colour: $body-colour;
$quick-giving-donation-amount-selected-background-colour: $black;
$quick-giving-donation-amount-selected-figure-colour: $brand-primary;

// Home quick giving panel
$home-quick-giving-enabled: true;
$home-quick-giving-type: simple;
$home-quick-giving-max-width: 100%;
$home-quick-giving-content-max-width: 100%;
$home-quick-giving-margin-top: 0;
$home-quick-giving-margin-bottom: 0;
$home-quick-giving-padding-y: $spacer * 5;
$home-quick-giving-background-colour: $quick-giving-background-colour;
$home-quick-giving-background-image: null;
$home-quick-giving-background-image-opacity: 0.5;
$home-quick-giving-donation-amount-background-colour: $donate-colour;
$home-quick-giving-donation-amount-figure-colour: $body-colour;
$home-quick-giving-donation-amount-border-width: 2px;
$home-quick-giving-donation-amount-border-colour: $body-colour;
$home-quick-giving-donation-amount-selected-background-colour: $black;
$home-quick-giving-donation-amount-selected-figure-colour: $brand-primary;

$home-quick-giving-donate-btn-background-colour: $btn-background-colour;
$home-quick-giving-donate-btn-colour: $btn-text-colour;
$home-quick-giving-colour: $body-colour;
$home-quick-giving-heading-colour: $headings-colour;
$home-quick-giving-heading-font-size: $font-size-h1;

// Totalisers
$totaliser-enabled: true;
$totaliser-layout: horizontal;
$totaliser-width: 60px;
$totaliser-panel-background-colour: $grey-lightest;

// Comments
$comment-add-image-enabled: true;
$comment-add-video-enabled: true;

// Blog details
$blog-details-enabled: true;
$blog-details-background-colour: $context-container-background-colour;
$blog-details-padding: $context-container-padding;

// FAQs
$faq-content-max-width: $post-content-max-width;
$faq-item-header-background-colour: $black;
$faq-item-header-padding: $context-container-padding;
$faq-item-header-font-size: $font-size-h2;
$faq-item-btn-background-colour: $brand-primary;
$faq-item-btn-chevron-colour: $black;
$faq-item-hover-btn-background-colour: darken($brand-primary, 10%);
$faq-item-hover-btn-chevron-colour: text-contrast(
  $faq-item-hover-btn-background-colour
);

// Shop
$shop-enabled: true;
$basket-link-only-on-shop-pages: false;

// Departments list
$department-list-width: 100%;
$department-list-background-colour: $context-container-background-colour;
$department-list-padding: $context-container-padding;

// Product-price
$product-price-colour: darken($brand-secondary, 5%);
$product-price-font-size: $font-size-h3;

// Product post
$product-post-content-max-width: 768px;

// Cookie consent
$cookie-consent-position: bottom;
$cookie-consent-background-colour: rgba($black, 0.75);
$cookie-consent-colour: text-contrast($cookie-consent-background-colour);
$cookie-consent-btn-background-colour: $brand-primary;
$cookie-consent-btn-text-colour: $black;
